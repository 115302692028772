import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Pages ****/
const ThreatActors = Loadable(lazy(() => import('../views/dashboards/ThreatActors')));
const Settings = Loadable(lazy(() => import('../views/dashboards/Settings')));
const Details = Loadable(lazy(() => import('../views/dashboards/Details')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/*****Routes******/

export const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/auth/signin" /> },
      { path: '*', element: <Navigate to="/auth/signin/keyExpired" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'signin', element: <LoginFormik /> },
      { path: 'signin/:keyExpired', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export const AuthorizedRoutes = [
  { path: '*', name: 'Universal', element: <Navigate to="/dashboards/home" /> },
  {
    path: '/dashboards/',
    element: <FullLayout />,
    children: [
      { path: 'home', name: 'Minimal', exact: true, element: <ThreatActors /> },
      { path: 'settings', name: 'Minimal', exact: true, element: <Settings /> },
      { path: 'home/details/:id', name: 'Mitigation Details', exact: true, element: <Details /> },
    ],
  },
];
