import rootParams from './rootParams';

const authTwoFA = async (email, otp) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    email,
    otp,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(`${rootParams.rootUrl}admin/auth/validate-two-fa`, requestOptions);

  return response.json();
};

export default authTwoFA;
