import rootParams from '../rootParams';

const getClientsSettings = async (params) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const baseUrl = `${rootParams.rootUrl}admin/client-management/list-clients`;
  let url;

  if (params) {
    const queryParams = {};

    url = `${baseUrl}?${new URLSearchParams(queryParams)}`;
  } else {
    url = baseUrl;
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error('Get clients failed');
  }

  return response.json();
};

export default getClientsSettings;
