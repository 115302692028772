import rootParams from '../rootParams';

const getClientDetailsSettings = async (params) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const baseUrl = `${rootParams.rootUrl}admin/client-management/get-single-client-details`;
  let url;

  if (params) {
    const queryParams = {};

    if (params.client_id) {
      queryParams.client_id = params.client_id;
    }

    url = `${baseUrl}?${new URLSearchParams(queryParams)}`;
  } else {
    url = baseUrl;
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error('Get client details failed');
  }

  return response.json();
};

export default getClientDetailsSettings;
