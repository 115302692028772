import rootParams from './rootParams';

const getUserDataApi = async (navigate) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const baseUrl = `${rootParams.rootUrl}admin/dashboard/clients-info`;

  const response = await fetch(baseUrl, requestOptions);

  if (!response.ok) {
    localStorage.clear();
    navigate('/auth/signin/keyExpired');
    throw new Error('Get user data failed');
  }

  return response.json();
};

export default getUserDataApi;
