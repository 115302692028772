import rootParams from './rootParams';

const login = async (email) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    email,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(`${rootParams.rootUrl}admin/auth/login`, requestOptions);

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};

export default login;
